@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Vithkuqi&family=Freckle+Face&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeueLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  font-family: "Helvetica Neue", sans-serif;
}

/* Hide arrows in WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  height: 100%;
  margin: 0;
}

#nav-bar {
  box-shadow: -1px 2px 5px 0px #80808033 !important;
}

.loader {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.darkLoader {
  width: 14px;
  height: 14px;
  border: 2px solid #000000;
  border-bottom-color: transparent;
  border-radius: 68%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.5s linear infinite;
}

.approveWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: gray;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alertComponent {
  position: fixed;
  z-index: 200;
  top: 10%;
  right: 0%;
  /* box-shadow: 0px 2px 7px #4c9051; */
}

#successAlert {
  background-color: #bef5c2;
  color: green;
  border-top: 2px solid green;
}

#declineAlert {
  background-color: #ffe1e1;
  color: red;
  border-top: 2px solid red;
}

.box {
  width: 100%;
  height: 200px;
  background-color: #fff;
  margin: 100px auto;
  filter: url(#round);
  position: relative;
  rotate: 180deg;
  margin-top: -10px;
  border-top-right-radius: 30px;
}

.box::before,
.box::after {
  content: "";
  position: absolute;
  border-radius: 30px;
  width: 50%;
  height: 92%;
  bottom: 162px;
  background: inherit;
  left: -40px;
  right: 158px !important;
  background: inherit;
}

.box::after {
  right: -40px;
  left: auto;
}

/* ===================== Mission Section ============================== */

#mission-title {
  font-family: "Freckle Face";
  font-size: 48px;
  color: #216206;
  width: 244px;
}

.mission-section-wrapper > p {
  line-height: 27px;
}

/* ============ Footer ========================== */
.footer {
  background: #ccd1ca;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  /* padding: 62px; */
}

.quick-link {
  line-height: 30px;
  font-size: 14px;
}

#social-ul {
  display: flex;
  justify-content: space-between;
}

.form-wrapper-aux {
  margin: 60px 0;
}

/* ================================================== */
.no-top {
  padding-top: 0;
}

.no-top {
  padding-top: 0;
}

/* Login Section */

.login-form {
  flex: 0 0 55%;
  font-family: "Lexend", sans-serif;
  padding: 16px;
}

.login-form h1 {
  font-family: "Freckle Face", sans-serif;
}

.login-form form {
  font-family: "Lexend", sans-serif;
}

.aside {
  box-shadow: 1px 0px 3px #dfdcdc;
  margin-top: 12px;
}

.top-nav {
  box-shadow: 1px 0px 3px #dfdcdc;
}

#side-link-state {
  display: flex;
  align-items: center;
  color: rgb(102, 102, 102);
  padding: 1rem;
}

#side-link-state:hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  background-color: #216206;
}

#side-link-state:hover path {
  fill: #fff;
}

/* .active {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff !important;
  background-color: #216206;
}

.active path {
  fill: #fff !important;
} */

/*================== Dashboard =========================== */
.content-section {
  background-color: #f1f5f9;
  margin-top: -10px !important;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0 !important;
}

.dashboard-section {
  background-color: #f1f5f9;
  margin-top: -10px !important;
  min-height: 100vh;
  /* padding-left: 0px !important;
  padding-right: 0 !important; */
}

.reg-section {
  /* background-color: #f1f5f9; */
  background-color: #fff;
  min-height: 100vh;
  padding-left: 0px !important;
  padding-right: 0 !important;
  line-height: 34px;
}

#dashboard-avatar {
  width: 80%;
}

#dashboard-logo {
  width: 60%;
}

#single-logo {
  width: 4%;
}

.nav-hover {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: rgb(107, 107, 107);
}

/* .nav-hover:hover {
  color: #fff;
}

.nav-hover:hover path {
  fill: #fff;
} */

#single-logo {
  width: 4%;
}

.weather-card {
  background-color: #e3f3e3;
  border-radius: 20px;
}

.chart-card-wrapper {
  box-shadow: 0px 4px 9px 0px #808080a8;
  padding: 27px;
  font-size: 14px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chart-card-one {
  background-color: #b88c19;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-top: -61px;
  padding: 0 10px;
}

.chart-card-two {
  background-color: #4caf50;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin-top: -61px;
  padding: 0 10px;
}

/* =========== Produce Listings ============================= */

.add-to-list {
  width: 130px;
  height: 35px;
  border-radius: 5px;
}

#add-btn {
  background-color: #216206;
  color: #fff;
}

#sort-btn {
  border: 1px solid #216206;
  color: #216206;
  background-color: transparent;
}

.selected-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#selected-btn {
  all: unset;
  width: 70px !important;
  border: 1px solid #216206;
  color: #216206;
  background-color: transparent;
  position: relative;
  padding: 0 8px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

/* #cancle{
  position: absolute;
  top: 5px;
  right: 5px;
} */

/* ============ Produce Form ============================ */
.produce-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.produce-form {
  /* width: 70%; */
  width: 50%;
  background-color: #fff;
  /* box-shadow: 0px 0 3px 0px rgb(170, 170, 170); */
  padding: 40px;
  margin-top: 22px;
}

.produce-input {
  /* border: .5px solid rgb(185, 185, 185); */
  border-radius: 5px;
  color: rgb(73, 72, 72);
}

.produce-input:focus {
  outline: unset !important;
}

.produce-input::placeholder {
  font-weight: 100;
}

/* ================================= */

.option-btn-wrapper {
  margin: 10px;
  display: flex;
  justify-content: right;
}

.mobile-tab {
  display: block !important;
}

.side-btn {
  position: fixed;
  bottom: 50%;
  margin: 0;
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.side-btn:hover .rot-up {
  transform: rotate(30deg);
}

.side-btn:hover .rot-down {
  transform: rotate(-30deg);
}

/* ====================================================
=========================================================== */
.side-link {
  padding: 10px 20px;
  box-shadow: 0 0 1px 0px rgb(148, 148, 148);
  text-decoration: none;
  color: #5d657b;
  font-weight: 500;
  width: 100%;
}

.side-link:hover {
  color: #282b33;
}

.subLink-wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
  transform-origin: top;
  padding-left: 0;
}

.side-link.open + .subLink-wrapper {
  max-height: 300px;
  /* Adjust the value based on your content height */
  transition: max-height 0.5s ease-in;
  transform-origin: top;
}

.side-icon {
  font-size: 18px;
}

.side-nav-sublink {
  text-decoration: none;
  color: rgb(112, 112, 112);
  font-size: 14px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-nav-sublink > a {
  text-decoration: none;
  color: rgb(90, 90, 90);
}

.side-nav-sublink:hover {
  background-color: rgba(94, 146, 206, 0.089);
}

.side-nav-sublink:hover > a {
  color: rgb(94, 146, 206);
}

/* .active {
  background-color: rgb(2, 92, 6);
} */

#total-balance {
  background-color: #0b111e;
}

#account-details {
  background-color: rgb(201, 220, 228);
}

#balance {
  color: #0cbde9;
  font-size: 40px;
}

.account-info {
  color: #333335;
  font-size: 18px;
  font-weight: 500;
}

.dashboard-label {
  font-size: 18px;
  color: rgb(104, 103, 103);
}

table {
  background-color: #fff;
}

thead {
  background-color: #000000a3;
  color: #fff;
}

tr:nth-child(even) {
  background-color: #ddd7d7;
}

tr#receipt-row {
  background-color: transparent;
  border-bottom: 1px solid rgb(221, 218, 218);
}

.transfer-balance {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: rgb(201, 220, 228);
  color: #6b7280;
  padding: 20px;
}

.eye-drop {
  font-size: 25px;
  list-style: none;
  cursor: pointer;
}

.chargesContainer {
  display: flex;
  justify-content: right;
}

.chargeWrapper {
  list-style: none;
  display: flex;
  font-size: 14px;
  color: gray;
}

.receipt-btn {
  padding: 8px;
  border-radius: 5px;
  background-color: transparent;
  color: rgb(66, 125, 253);
  border: 1px solid rgb(66, 125, 253);
}

.receipt-btn:hover {
  background-color: rgb(66, 125, 253);
  color: #fff;
}

.success-icon {
  font-size: 20px;
}

.cheque-input {
  height: 200px;
  padding: 20px;
  border: 1px solid rgb(177, 178, 182);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-cheque-btn {
  border: 1px solid gray;
  padding: 8px;
  border-radius: 8px;
  color: rgb(92, 92, 92);
  font-size: 15px;
  cursor: pointer;
}

.upload-cheque-btn:hover {
  background-color: rgb(102, 103, 105);
  color: #fff;
}

#deposit-cheque-btn {
  background-color: rgb(11, 17, 30);
  color: #fff;
  border: none;
  width: 35%;
  height: 45px;
  text-transform: uppercase;
  font-size: 12px;
}

.badge {
  padding: 4px;
  color: #fff;
  border-radius: 2px;
  font-size: 11px;
}

#pending {
  background-color: orange;
}

#approved {
  background-color: #216206;
}

#danger {
  background-color: rgb(236, 61, 61);
}

.card {
  border: none;
  color: #fff;
  width: 80%;
  height: 270px;
  box-shadow: 8px 6px 7px 0px rgb(170, 170, 170);
}

.cryptoWrapper {
  display: flex;
  align-items: center;
}

.cryptoAddress {
  background-color: rgba(128, 128, 128, 0.295);
  color: #504d4d;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px 20px;
}

.cryptoAddress:focus {
  outline: none !important;
}

.copyBtn {
  background-color: rgb(201, 220, 228);
  padding: 9px 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid rgb(201, 220, 228);
}

.copyBtn:hover {
  background-color: rgb(255, 136, 0);
}

.profileBox {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  /* background-color: #216206;  */
  position: relative;
  /* outline: 1px solid #bdbbbb; */
}

.avatarInputIcon {
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  bottom: -1px;
  right: 5px;
  width: 34px;
}

/* =========================================
============================================== */
.hero {
  background-color: #0b111e;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.unscrolled_login_btn {
  font-size: 0.7rem;
  padding: 16px 20px !important;
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #fff;
}

.scrolled_login_btn {
  font-size: 0.7rem;
  padding: 16px 20px !important;
  background-color: #fff !important;
  border: 1px solid #0b111e;
  color: #0b111e;
}

.unscrolled_signup_btn {
  font-size: 0.7rem;
  padding: 16px 20px !important;
  background-color: #fff !important;
  /* border: 1px solid #fff; */
  color: #0b111e;
}

.scrolled_signup_btn {
  font-size: 0.7rem;
  padding: 16px 20px !important;
  background-color: #000000 !important;
  /* border: 1px solid #0B111E; */
  color: #fff;
}

#zero_padding {
  padding: auto;
}

.unscrolled_link {
  text-decoration: none;
  color: #fff;
}

.scrolled_link {
  text-decoration: none;
  color: #0b111e;
}

.banner_column {
  margin-top: 100px;
  padding: 135px !important;
}

.banner_debit_card {
  top: 80px;
  right: 8px;
  width: 58%;
  position: absolute;
}

.showMobile {
  display: none;
}

.banner_title {
  font-size: 4.6rem;
  letter-spacing: -0.125rem;
  line-height: 5rem;
  font-weight: normal;
}

.banner_subtitle {
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: 0px;
  line-height: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.banner_input {
  font-size: 1rem;
  border-radius: 4px;
  width: 50%;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
  line-height: 2rem;
  border-style: solid;
  background: none;
  color: rgb(255, 255, 255);
  margin-bottom: 2rem;
}

.banner_error {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 2rem;
  min-height: 25px;
  color: rgb(226, 134, 126);
  display: none;
}

.banner_button {
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  color: rgb(11, 17, 30);
  background-color: rgb(251, 249, 249);
  border: 0px;
  border-radius: 4px;
  font-family: generalgrotesque, "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.banner_pictures_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 4rem;
}

.banner_img {
  height: 57px;
  width: auto;
}

.sponsors_wrapper {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
}

.sponsors_img_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.img_case {
  padding: 15px 0;
}

.sponsors_img {
  max-width: 100%;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.advice_section {
  background-color: rgb(251, 249, 249);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6.25rem 1.5rem;
}

.advice_section_container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  max-width: 90rem;
}

.advice_title {
  font-size: 4rem;
  letter-spacing: -0.078rem;
  line-height: 5.5rem;
}

.advice_row {
  display: flex;
  flex: 1 1 0%;
  margin-top: 2.5rem;
  max-width: 90rem;
}

.advice_column1 {
  margin-right: 5rem;
}

.advice_column2 {
  margin-left: 5rem;
}

.advice_column_title {
  color: rgb(11, 17, 30);
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 1rem;
  /* text-align: center; */
}

.fees_section_wrapper {
  display: flex;
  background-color: rgb(201, 220, 228);
  /* flex-direction: column; */
  flex: 1 1 0%;
  padding: 4rem 1.5rem;
  -webkit-box-pack: center;
  justify-content: center;
  background-image: none;
}

.fees_row {
  display: flex;
  flex: 1 1 0%;
  margin-top: 2.5rem;
  max-width: 80rem;
}

.fees_column {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px;
  flex: 1 1 0%;
  width: 60%;
}

.fees_column2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.fees_column_title {
  color: rgb(11, 17, 30);
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: -0.047rem;
  line-height: 3.375rem;
  margin-top: 0px;
  margin-bottom: 2rem;
}

.fees_column_list {
  list-style-type: disc;
  padding-left: 0;
  list-style-position: inside;
}

.fees_column_text {
  color: rgb(109, 112, 121);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1rem;
  margin-bottom: 0px;
  margin-top: 2rem;
  width: 73%;
}

.fees_column_image {
  width: 100%;
  height: auto;
}

.protection_section_wrapper {
  display: flex;
  background-color: rgb(241, 234, 232);
  padding: 4rem 1.5rem;
  justify-content: center;
}

.proctection_section_container {
  display: flex;
  /* flex: 1 1 0%; */
  margin-top: 2.5rem;
  max-width: 80rem;
  flex-direction: column;
}

.proctection_section_title {
  font-size: 4rem;
  letter-spacing: -0.078rem;
  line-height: 4.5rem;
  color: rgb(11, 17, 30);
  font-weight: 300;
}

.proctection_section_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.proctetion_section_col {
  width: 30%;
  padding-bottom: 2rem;
}

.proctetion_section_col_icon {
  color: rgb(11, 17, 30);
  background-color: rgb(255, 255, 255);
  padding: 4px 0.5rem;
  border-radius: 9999px;
  display: inline-table;
  margin: 12px 0;
}

.protection_img {
  height: 20px;
  width: 20px;
}

.security_security_wrapper_desktop {
  background-image: url(./assets/dim-treetops-medium.webp);
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  padding: 4rem 1.5rem;
  align-items: center;
}

.security_section_container {
  width: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.security_sect_row {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  flex-wrap: wrap;
  align-items: center;
}

.security_sect_col {
  /* background-color: blue; */
  margin: 20px;
  width: 40%;
}

.security_sect_title {
  font-size: 3.5rem;
  letter-spacing: -0.078rem;
  line-height: 4.5rem;
  margin-top: 0px;
  margin-bottom: 2rem;
}

.security_sect_col_container {
  display: flex;
  /* padding: 30px; */
}

.security_sect_col_title {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  line-height: 1.875rem;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.security_sect_col_text {
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
}

.security_sect_col_icon {
  align-self: flex-start;
  max-width: 48px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  background-color: rgb(255, 255, 255);
  /* padding: 3px 9px; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
}

#badgeImg {
  max-width: 240px;
  margin-top: 3rem;
}

.security_text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1rem;
  max-width: 800px;
  margin-bottom: 0px;
  padding-top: 2rem;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.rating_sect_wrapper {
  display: flex;
  background-color: rgb(251, 249, 249);
  padding: 4rem;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}

.rating_testimony_container {
  width: 80%;
}

.stars_row {
  display: flex;
  align-items: end;
  margin-bottom: 20px;
}

.star_icon {
  height: 24px;
  width: 24px;
}

#star_text {
  color: rgb(11, 17, 30);
  font-size: 0.65rem;
  font-weight: bolder;
  letter-spacing: 0.0625rem;
  line-height: 1rem;
  text-transform: uppercase;
  padding-left: 5px;
}

.rating_testimony_container {
  display: flex;
}

.rating_testimony_col1 {
  width: 40%;
}

.rating_testimony_col2 {
  width: 20%;
  padding: 0 10px;
}

.rate_title {
  color: rgb(11, 17, 30);
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: -0.016rem;
  line-height: 2.75rem;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.rate_text {
  color: rgb(11, 17, 30);
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 70%;
}

.more_card_section {
  display: flex;
  background-color: rgb(11, 17, 30);
  display: flex;
  justify-content: center;
}

.more_card_container {
  width: 80%;
  margin: 80px 0;
}

#more_card_img {
  max-width: 512px;
  height: auto;
}

.more_card_row {
  display: flex;
  color: #fff;
}

.more_card_col {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#more_card_title {
  font-size: 4rem;
  letter-spacing: -0.078rem;
  line-height: 4.5rem;
  margin-bottom: 20px;
}

.more_card_btn {
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  color: rgb(11, 17, 30);
  background-color: rgb(251, 249, 249);
  border: 0px;
  border-radius: 4px;
  font-family: generalgrotesque, "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
}

.features_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 249, 249);
}

.features_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}

.features_title {
  font-size: 4rem;
  letter-spacing: -0.078rem;
  line-height: 4.5rem;
  color: rgb(11, 17, 30);
  font-weight: 300;
}

.features_row {
  display: flex;
  justify-content: space-between;
}

.features_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
  text-align: center;
}

.features_img_wrapper {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.features_col_title {
  color: rgb(11, 17, 30);
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0.5rem;
}

.newfooter_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(11, 17, 30);
  padding: 6rem 2rem;
}

.newfooter_container {
  width: 90%;
}

.newfooter_img_wrapper {
  display: flex;
  justify-content: space-between;
  width: 20%;
}

.newfooter_img {
  height: 56px;
  width: auto;
  margin-right: 15px;
}

#security_icon {
  height: 16px;
  width: 16px;
  opacity: 0.4;
  margin-right: 10px;
}

#security_text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.25rem;
}

.newfooter_notes {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1rem;
  margin-bottom: 0.875rem;
  margin-top: 40px;
}

.loginSection_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginSection_form {
  width: 400px;
  height: 400px;
  /* background-color: gray; */
}

.loginSection_notice {
  background-color: #eee2b9;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 32px;
}

.loginSection_notice_title {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.loginSection_notice_text {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  margin-top: 10px;
}

.loginSection_title {
  margin: 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: 0;
  text-transform: none;
}

.loginsection_form_input {
  padding: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0b111e;
  height: auto;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 0 1px #0b111e99 inset;
  box-shadow: 0 0 0 1px #0b111e99 inset;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
  display: block;
  width: 100%;

  margin: 30px 0;
}

.loginsection_form_btn {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fbf9f9;
  background-color: #0b111e;
  width: 108px;
  height: 50px;
  border-radius: 5px;
}

.loginsection_form_question {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  margin-top: 15px;
}

.signup_form_row {
  display: flex;
  justify-content: space-between;
}

.signup_form_col {
  width: 49%;
}

.unStyledLink {
  text-decoration: none;
  color: #0b111e;
}

/* =========================================
============================================== */

.heroBtnWrapper {
  width: 30%;
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
}

.signUp {
  /* width: 200px; */
  padding: 10px 60px;
  background-color: rgb(214, 141, 4);
  color: #fff;
  font-weight: 400;
  box-shadow: 1px 1px 3px 1px #3c3b3b;
}

.login {
  /* width: 200px; */
  padding: 10px 60px;
  background-color: #0c600c;
  color: #fff;
  font-weight: 400;
  box-shadow: 1px 1px 3px 1px #3c3b3b;
}

.partners {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.receipt-data {
  color: rgb(66, 125, 253);
}

.active {
  background-color: #0c600c;
  /* padding: 10px; */
  text-decoration: none;
  color: #fff !important;
  /* border-radius: 6px; */
}

.transparent-div {
  width: 100%;
  min-height: 100vh;
  /* background-color: rgba(29, 28, 28, 0.068); */
  position: fixed;
  z-index: 2;
}

#content-pad {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.wrapper {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.otp {
  width: 60%;
  background-color: #a9a9a98f;
  margin: 10px;
}

#otp-wrapper {
  display: flex;
  justify-content: center;
}

.rat {
  background-color: #a9a9a98f;
  width: 48px !important;
  height: 48px !important;
  border: none;
}

.otp-resend {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.otp-resend button {
  color: orangered !important;
}

.ot-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.updateWrapper {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kyc {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#depositwrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 100%; */
}

#suspended {
  background-color: orange;
}

.pictureHolder {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background-color: white;
}

.statusWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roleButton {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: white;
}

.roleButton:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.roleButton:last-child {
  margin-right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.atmCard_row {
  display: flex;
}

.atmCard_col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
}

.atmCard {
  min-width: 50%;
}

.ATMCard_form {
  background-color: white;
  width: 70%;
}

.about_section {
  display: flex;
  justify-content: center;
  background-color: rgb(251, 249, 249);
}

.about_col {
  width: 40%;
}

.about_ul {
  border-left: 3px solid rgba(11, 17, 30, 0.06);
  margin-left: 25.5px;
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  padding-left: 0;
}

.about_li {
  display: flex;
  padding: 20px 0;
}

.about_icon {
  cursor: default;
  display: flex;
  background-color: rgb(201, 220, 228);
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 9999px;
  width: 200px;
  height: 29px;
  margin-right: 20px;
}

#about_title {
  color: rgb(11, 17, 30);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.0625rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

#about_subtitle {
  color: rgb(11, 17, 30);
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 300;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0.375rem;
}

#about_desc {
  color: rgb(109, 112, 121);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5rem;
  margin-top: 0px;
  margin-bottom: 2rem;
}

#about_banner{
  background-image: url(./assets/who-we-are-header.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;
}

/* 
=============================================================
New
=============================================================
 */

/* =================== Mobile breakpoint =========================== */
@media screen and (max-width: 768px) {
  #zero_padding {
    padding: 9px 14px !important;
  }

  .banner_debit_card {
    width: 100%;
    position: unset;
    display: none;
  }

  .banner_column {
    padding: 20px !important;
  }

  .showMobile {
    display: block;
    margin-top: -95px;
  }

  .banner_title {
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
    font-weight: 300;
    letter-spacing: -0.016rem;
    line-height: 2.75rem;
    margin-bottom: 2rem;
    margin-top: 0px;
  }

  .banner_input {
    width: 100%;
  }

  .banner_button {
    width: 100%;
  }

  .banner_pictures_wrapper {
    width: 100%;
  }

  .banner_img {
    height: 48px;
  }

  .sponsors_wrapper {
    padding: 0 20px;
  }

  .sponsors_img_container {
    flex-wrap: wrap;
    width: 100%;
  }

  .img_case {
    width: 30%;
  }

  .advice_row {
    flex-direction: column;
    text-align: center;
  }

  .advice_column1 {
    margin-right: unset;
  }
  .advice_column2 {
    margin-left: unset;
  }

  .advice_title {
    color: rgb(11, 17, 30);
    font-size: 3rem;
    font-weight: 300;
    letter-spacing: -0.047rem;
    line-height: 3.375rem;
    margin-top: 0px;
    margin-bottom: 2rem;
    max-width: 600px;
    text-align: center;
  }

  .fees_section_wrapper {
    padding: 1rem 1.5rem;
  }

  .fees_row {
    flex-direction: column-reverse;
    width: 100%;
  }

  .fees_column {
    width: 100%;
  }

  .fees_column2 {
    width: 100%;
  }

  .fees_column_image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .security_security_wrapper_desktop {
    padding: 30px 0;
  }

  .security_section_container {
    width: 100%;
  }

  .proctetion_section_col {
    width: 100%;
  }

  .security_sect_col {
    width: 100%;
  }

  .security_sect_title {
    font-size: 2.5rem;
  }

  .security_sect_title {
    margin: 0;
  }

  #badgeImg {
    max-width: 200px;
    margin-top: 3rem;
  }

  .security_text {
    padding: 30px;
  }

  .rating_sect_wrapper {
    padding: 2rem;
  }

  .rating_testimony_container {
    flex-direction: column;
  }

  .rating_testimony_col1 {
    width: 100%;
  }

  .rating_testimony_col2 {
    width: 100%;
    padding: 0;
  }

  .testimonia {
    padding: 20px 0;
  }

  .rate_text {
    width: 100%;
  }

  #more_card_img {
    max-width: 300px;
  }

  .more_card_row {
    flex-direction: column;
    justify-content: center;
  }

  .more_card_col {
    width: 100%;
  }

  #more_card_title {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .features_container {
    width: 100%;
  }

  .features_row {
    flex-direction: column;
  }

  .features_col {
    flex-direction: row-reverse;
    text-align: unset;
    margin: 0 20px;
  }

  .features_img_wrapper {
    display: flex;
    align-items: center;
  }

  .features_col_text {
    text-align: left;
    padding-right: 20px;
  }

  .features_title {
    font-size: 3rem;
  }

  .newfooter_wrapper {
    padding: 2rem 1rem;
  }

  .newfooter_container {
    width: 100%;
  }

  .newfooter_img_wrapper {
    width: 80%;
  }

  .loginSection_form {
    padding: 0 24px;
  }

  .card {
    width: 90%;
    height: 250px;
    margin-bottom: 28px;
  }
  /* ================ */

  .produce-form {
    width: 100%;
    padding: 20px;
    margin-top: 22px;
  }

  .produce-form-wrapper {
    padding: 10px !important;
  }

  .content-section {
    padding: 1rem 0;
  }

  .heroBtnWrapper {
    width: 90%;
    /* margin: 0 10px; */
  }

  .dashboard-section {
    background-color: #f1f5f9;
    margin-top: -10px !important;
    min-height: 100vh;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .atmWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .wrapper {
    width: 100%;
  }

  .otp {
    width: 100%;
  }

  .rat {
    margin-right: 10px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .aside {
    margin-top: 32px;
  }

  #dashboard-avatar {
    width: 45% !important;
  }

  #menu_btn {
    padding-right: 0 !important;
  }

  .ATMCard_form {
    width: 100%;
  }

  .about_col{
    width: 100%;
  }

  .about_ul{
    margin-left: 0;
    padding-top: 1.25rem;
    padding-bottom: unset;
    padding-left: 20px;
    padding-right: 20px;
  }

  #about_banner{
    height: 65vh;
  }
}
